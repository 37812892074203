import React, { useEffect, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Schema, ButtonToolbar, Button, Input, Placeholder, Grid, Row, Col, ButtonGroup, InputNumber, IconButton, Panel, Loader, Divider } from "rsuite";
import { format, parseISO, addDays } from "date-fns";
import { setValue } from "react-hook-form"; // Add this line to import the setValue function
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import MinusRoundIcon from "@rsuite/icons/MinusRound";
import AddOutlineIcon from "@rsuite/icons/AddOutline";
import API from "../../../utils/API";

function SingleConcreteFieldForm() {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const is_add_mode = !searchParams.get("csid");
  const concrete_sample_id = searchParams.get("csid");
  const workorder_id = searchParams.get("wo");
  const workorder_uuid = searchParams.get("woid");
  const copy_last_id = searchParams.get("cp");

  const [is_loading, setIsLoading] = useState(true);

  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const watchSampleDate = watch("sample_date", undefined);
  const { fields, append, remove } = useFieldArray({ name: "cylinder_specimens", control });

  useEffect(() => {
    if (!is_add_mode) {
      API.get(`/concrete_samples/${concrete_sample_id}`)
        .then((r) => {
          reset({
            id: r.data.id,
            workorder_id: r.data.workorder_id,
            supplier: r.data.supplier,
            plant: r.data.plant,
            sheet_number: r.data.sheet_number,
            mix_code: r.data.mix_code,
            design_mpa: r.data.design_mpa,
            aggregate_size: r.data.aggregate_size,
            design_slump: r.data.design_slump,
            truck_number: r.data.truck_number,
            batch_date: r.data.batch_date && format(parseISO(r.data.batch_date), "Y-MM-dd"),
            batch_time: r.data.batch_time && format(parseISO(r.data.batch_time.split("Z")[0]), "HH:mm"),
            sample_date: r.data.sample_date && format(parseISO(r.data.sample_date), "Y-MM-dd"),
            sample_time: r.data.sample_time && format(parseISO(r.data.sample_time.split("Z")[0]), "HH:mm"),
            docket_number: r.data.docket_number,
            load_size: r.data.load_size,
            progressive_total: r.data.progressive_total,
            initial_slump: r.data.initial_slump,
            final_slump: r.data.final_slump,
            ambient_temperature: r.data.ambient_temperature,
            concrete_temperature: r.data.concrete_temperature,
            entrained_air: r.data.entrained_air,
            entrained_air_method: r.data.entrained_air_method,
            sampling_method: r.data.sampling_method,
            compaction_type: r.data.compaction_type,
            pour_location: r.data.pour_location,
            sample_comments: r.data.sample_comments,
            cylinder_specimens: r.data.cylinder_specimens
              ? r.data.cylinder_specimens
                  .map((cs) => {
                    return {
                      ...cs,
                      test_date: cs.test_date ? format(parseISO(cs.test_date), "y-MM-dd") : null,
                    };
                  })
                  .sort((a, b) => a.days_requested > b.days_requested || a.specimen_id.localeCompare(b.specimen_id))
              : undefined,
          });
          setIsLoading(false);
        })
        .catch((e) => console.log(e));
    } else {
      if (copy_last_id) {
        API.get(`/concrete_samples/${copy_last_id}`)
          .then((r) => {
            reset({
              // id: r.data.id,
              workorder_id: r.data.workorder_id,
              supplier: r.data.supplier,
              plant: r.data.plant,
              sheet_number: r.data.sheet_number,
              mix_code: r.data.mix_code,
              design_mpa: r.data.design_mpa,
              aggregate_size: r.data.aggregate_size,
              design_slump: r.data.design_slump,
              // truck_number: r.data.truck_number,
              batch_date: r.data.batch_date && format(parseISO(r.data.batch_date), "Y-MM-dd"),
              // batch_time: r.data.batch_time && format(parseISO(r.data.batch_time.split("Z")[0]), "HH:mm"),
              sample_date: r.data.sample_date && format(parseISO(r.data.sample_date), "Y-MM-dd"),
              // sample_time: r.data.sample_time && format(parseISO(r.data.sample_time.split("Z")[0]), "HH:mm"),
              // docket_number: r.data.docket_number,
              // load_size: r.data.load_size,
              // progressive_total: r.data.progressive_total,
              // initial_slump: r.data.initial_slump,
              // final_slump: r.data.final_slump,
              // ambient_temperature: r.data.ambient_temperature,
              // concrete_temperature: r.data.concrete_temperature,
              // entrained_air: r.data.entrained_air,
              // entrained_air_method: r.data.entrained_air_method,
              sampling_method: r.data.sampling_method,
              compaction_type: r.data.compaction_type,
              pour_location: r.data.pour_location,
              // sample_comments: r.data.sample_comments,
              cylinder_specimens: r.data.cylinder_specimens
                ? r.data.cylinder_specimens
                    .map((cs) => ({
                      ...cs,
                      mould_number: undefined,
                      test_time: undefined,
                      test_date: format(parseISO(cs.test_date), "y-MM-dd"),
                    }))
                    .sort((a, b) => a.days_requested > b.days_requested || a.specimen_id.localeCompare(b.specimen_id))
                : undefined,
            });
            setIsLoading(false);
          })
          .catch((e) => console.log(e));
      } else {
        reset({
          workorder_id: workorder_id,
          batch_date: format(new Date(), "Y-MM-dd"),
          sample_date: format(new Date(), "Y-MM-dd"),
        });
        setIsLoading(false);
      }
    }
  }, [concrete_sample_id, reset, is_add_mode, copy_last_id, workorder_id]);

  const onSubmit = (data) => {
    console.log(data);
    if (data.batch_date === "") {
      data.batch_date = null;
    }
    if (data.batch_time === "") {
      data.batch_time = null;
    }
    if (data.sample_date === "") {
      data.sample_date = null;
    }
    if (data.sample_time === "") {
      data.sample_time = null;
    }
    data.cylinder_specimens?.forEach((item, i) => {
      if (item.test_date === "") {
        data.cylinder_specimens[i].test_date = null;
      } else {
        data.cylinder_specimens[i].test_date = format(addDays(data.batch_date ? new Date(data.batch_date) : new Date(), data.cylinder_specimens[i].days_requested), "yyyy-MM-dd");
      }
      if (item.test_time === "") {
        data.cylinder_specimens[i].test_time = null;
      }
    });

    if (!is_add_mode) {
      API.patch(`/concrete_samples/${concrete_sample_id}`, data)
        .then((r) => navigate(`/workorders/${workorder_uuid}`))
        .catch((e) => console.log(e.response.data.errors));
    } else {
      API.post("/concrete_samples", data)
        .then((r) => navigate(`/workorders/${workorder_uuid}`))
        .catch((e) => console.log(e.response.data.errors));
    }
  };

  const handleDaysRequestedChange = (value, calcTarget) => {
    console.log("HERE", value, calcTarget);
    // if there is a sample date we should calculate the crush / test date
    if (watchSampleDate) {
      // setValue(calcTarget, format(addDays(Date.parse(watchSampleDate), value), "y-MM-dd"));
      setValue(calcTarget, format(addDays(parseISO(watchSampleDate), Number(value)), "y-MM-dd"));

      // console.log("Parsed Date:", addDays(parseISO(watchSampleDate), Number(value)))
    }
  };

  const nextString = (str) => {
    if (!str) return "A"; // return 'A' if str is empty or null

    let tail = "";
    let i = str.length - 1;
    let char = str[i];
    // find the index of the first character from the right that is not a 'Z'
    while (char === "Z" && i > 0) {
      i--;
      char = str[i];
      tail = "A" + tail; // tail contains a string of 'A'
    }
    if (char === "Z")
      // the string was made only of 'Z'
      return "AA" + tail;
    // increment the character that was not a 'Z'
    return str.slice(0, i) + String.fromCharCode(char.charCodeAt(0) + 1) + tail;
  };

  if (is_loading) {
    return <Loader center content="Loading..." />;
  } else {
    return (
      <Panel>
        <Form style={{ marginTop: "1rem" }} fluid onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register("id", { valueAsNumber: true })} />
          <input type="hidden" {...register("workorder_id", { valueAsNumber: true })} />
          <input type="hidden" {...register("sheet_number")} />

          <Grid fluid>
            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Supplier</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="supplier"
                  control={control}
                  render={({ field }) => <Input size="sm" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Plant</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="plant"
                  control={control}
                  render={({ field }) => <Input size="sm" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Mix Code</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="mix_code"
                  control={control}
                  render={({ field }) => <Input size="sm" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Design MPa</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="design_mpa"
                  control={control}
                  render={({ field }) => <InputNumber size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Agg Size (mm)</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="aggregate_size"
                  control={control}
                  render={({ field }) => <InputNumber size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Design Slump (mm)</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="design_slump"
                  control={control}
                  render={({ field }) => <InputNumber size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Truck No.</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="truck_number"
                  control={control}
                  render={({ field }) => <Input size="sm" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Batch Date</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="batch_date"
                  control={control}
                  render={({ field }) => (
                    <Input
                      size="sm"
                      type="date"
                      min="2000-01-01"
                      max="2050-01-01"
                      // defaultValue={format(new Date(), "y-MM-dd")}
                      id={field.name}
                      value={field.value || ""}
                      onChange={(value) => field.onChange(value)}
                      placeholder="Batch Date"
                    />
                  )}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Batch Time</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="batch_time"
                  control={control}
                  render={({ field }) => <Input size="sm" type="time" id={field.name} value={field.value || ""} onChange={(value) => field.onChange(value)} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Sample Date</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="sample_date"
                  control={control}
                  rules={{ required: "Sample Date is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        size="sm"
                        type="date"
                        min="2000-01-01"
                        max="2050-01-01"
                        // defaultValue={format(new Date(), "y-MM-dd")}
                        id={field.name}
                        value={field.value || ""}
                        onChange={(value) => field.onChange(value)}
                        placeholder="Batch Date"
                      />
                      {error && <span style={{ color: "red" }}>{error.message}</span>}
                    </>
                  )}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Sample Time</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="sample_time"
                  control={control}
                  rules={{ required: "Sample Time is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input size="sm" type="time" id={field.name} value={field.value || ""} onChange={(value) => field.onChange(value)} />{" "}
                      {error && <span style={{ color: "red" }}>{error.message}</span>}
                    </>
                  )}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Docket No.</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="docket_number"
                  control={control}
                  render={({ field }) => <Input size="sm" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Load Size</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="load_size"
                  control={control}
                  render={({ field }) => <Input size="sm" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Progressive Total</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="progressive_total"
                  control={control}
                  render={({ field }) => <Input size="sm" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Slump Initial</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="initial_slump"
                  control={control}
                  render={({ field }) => <InputNumber size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Slump Final</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="final_slump"
                  control={control}
                  render={({ field }) => <InputNumber size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Temperature (Amb)</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="ambient_temperature"
                  control={control}
                  render={({ field }) => <InputNumber size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Temperature (Conc)</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="concrete_temperature"
                  control={control}
                  render={({ field }) => <InputNumber size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : parseInt(v))} />}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Air %</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="entrained_air"
                  control={control}
                  render={({ field }) => (
                    <Input size="sm" type="number" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />
                  )}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Samp Method</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="entrained_air_method"
                  control={control}
                  render={({ field }) => (
                    <select id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)}>
                      <option value="7.2.1">7.2.1</option>
                    </select>
                  )}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Compaction Type</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="compaction_type"
                  control={control}
                  render={({ field }) => (
                    <select id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)}>
                      <option value="25H">25H</option>
                    </select>
                  )}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Pour Location</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="pour_location"
                  control={control}
                  rules={{ required: "Pour Location is required" }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input size="sm" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />
                      {error && <span style={{ color: "red" }}>{error.message}</span>}
                    </>
                  )}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "3px" }}>
              <Col xs={12}>
                <Form.ControlLabel>Comments</Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Controller
                  name="sample_comments"
                  control={control}
                  render={({ field }) => <Input size="sm" id={field.name} value={field.value || ""} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                />
              </Col>
            </Row>
          </Grid>

          <ButtonToolbar>
            <ButtonGroup>
              <Button
                onClick={() => {
                  append({
                    specimen_id: nextString(fields[fields.length - 1]?.specimen_id ?? ""),
                    mould_number: "",
                    days_requested: undefined,
                    curing_type: "M",
                    cap_type: "S",
                    test_date: undefined,
                    test_time: undefined,
                  });
                }}
                appearance="subtle"
                style={{ borderRadius: 0 }}
                startIcon={<AddOutlineIcon />}
              >
                Add Row
              </Button>
              <Button
                disabled={fields.length === 0}
                onClick={() => {
                  remove(fields.length && fields.length - 1);
                }}
                appearance="subtle"
                style={{ borderRadius: 0 }}
                startIcon={<MinusRoundIcon />}
              >
                Remove Row
              </Button>
            </ButtonGroup>
          </ButtonToolbar>

          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Mould</th>
                <th>Days</th>
                <th>Test Date</th>
                {/* <th>Curing</th> */}
                {/* <th>Cap</th> */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {fields?.map((item, i) => {
                const fieldName = `cylinder_specimens[${i}]`;
                return (
                  <tr key={`${fieldName}`} className="table-row-form">
                    <td>
                      <Controller
                        name={`${fieldName}.specimen_id`}
                        control={control}
                        render={({ field }) => <Input size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`${fieldName}.mould_number`}
                        control={control}
                        render={({ field }) => <Input size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />}
                      />
                    </td>
                    <td>
                      <Controller
                        name={`${fieldName}.days_requested`}
                        control={control}
                        // onChange={(e) => handleDaysRequestedChange(e, `cylinder_specimens.[${i}].test_date`)}
                        render={({ field }) => (
                          <InputNumber
                            size="sm"
                            id={field.name}
                            value={field.value}
                            onChange={(e) => {
                              handleDaysRequestedChange(e, `cylinder_specimens.[${i}].test_date`);
                              setValue(field.name, e === "" ? null : parseFloat(e));
                            }}
                          />
                        )}
                      />
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {watch(`cylinder_specimens.[${i}].test_date`) && format(parseISO(watch(`cylinder_specimens.[${i}].test_date`)), "eee d/M")}
                    </td>
                    <td>
                      <Controller
                        name={`${fieldName}.curing_type`}
                        control={control}
                        render={({ field }) => (
                          <Input size="sm" type="hidden" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />
                        )}
                      />
                    </td>

                    <td>
                      <Controller
                        name={`${fieldName}.cap_type`}
                        control={control}
                        render={({ field }) => (
                          <Input type="hidden" size="sm" id={field.name} value={field.value} onChange={(v) => field.onChange(v === "" || undefined ? null : v)} />
                        )}
                      />
                    </td>

                    <td>
                      <Input type="hidden" name={`${fieldName}.test_date`} {...register(`cylinder_specimens.${i}.test_date`)} />
                      <Input type="hidden" name={`${fieldName}.test_time`} {...register(`cylinder_specimens.${i}.test_time`)} />

                      {/* <button type="button" onClick={() => remove(i)} tabIndex={-1}>
                      Delete
                    </button> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Divider />
          <IconButton size="lg" block type="submit" appearance="primary" color="green" disabled={isSubmitting} icon={is_add_mode ? <AddOutlineIcon /> : <CheckRoundIcon />}>
            {is_add_mode ? "Add Concrete Sample" : "Save Changes"}
          </IconButton>
        </Form>
      </Panel>
    );
  }
}

export default SingleConcreteFieldForm;
